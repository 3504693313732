import React from "react";
import Jobs from "../components/jobs"
import Layout from "../components/layout";
import SEO from "../components/seo";
import tags from "../components/tags";
import cms from "../components/cms/cms";

const TagJobPage = ({ location }) => {

  const path = location.pathname;
  const tagId = path.split("/")[2];
  const tagObject = tags[tagId];
  const tagName = tagObject ? `${tagObject.name} `: "";
  const isTopLevelPage = (path === "/tags" || path === "/tags/") || !tagObject;

  return <Layout>
    <SEO
      keywords={[`${tagName}${cms.keyword} jobs`, `${tagName}${cms.keyword} developer jobs`, `${tagName}remote ${cms.keyword} jobs`, `${tagName}${cms.keyword} software engineering jobs`]}
      title={`${tagName}${cms.keyword} Jobs`}
      description={`Looking for a ${tagName}${cms.keyword} job? View all ${tagName}${cms.keyword} jobs on ${cms.siteUrl}. ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs.`}
    />
    {isTopLevelPage ? <Jobs/> : <Jobs tagProp={tagId} />}
  </Layout>

}

  
export default TagJobPage